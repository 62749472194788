<script setup lang="ts">
import {CTooltip} from "@coreui/vue";
import {computed} from "vue";
import QueueItem from "~/dto/queue/QueueItem";

const { t } = useI18n();

const props = defineProps({
  item: {
    type: Object as () => QueueItem,
    required: true
  },
  class: {
    type: String,
    default: null,
  },
  iconSize: {
    type: String,
    default: 'icon-lg',
  }
});

const waitingDurationOutput = computed<{h: number, m: number} | null>(() => {
  const duration = waitingDuration(props.item);

  if (!duration) {
    return null;
  }

  const hours = parseInt(duration.hours, 10);
  const minutes = parseInt(duration.minutes, 10);

  switch (true) {
    case hours > 0 && minutes > 0:
      return t('telegram.tracking.queue_time.time_hm', {h: hours, m: minutes});
    case hours > 0 && minutes === 0:
      return t('telegram.tracking.queue_time.time_h', {h: hours});
    case hours == 0 && minutes > 0:
      return t('telegram.tracking.queue_time.time_m', {m: minutes});
    default:
      return null;
  }
});
</script>

<template>
  <CTooltip
    v-if="waitingDurationOutput"
    :content="$t('telegram.tracking.queue_time.title')"
  >
    <template #toggler="{ on }">
      <div
        :class="props.class"
        v-on="on"
      >
        <i
          class="cil-clock icon me-1"
          :class="iconSize"
        /> {{ waitingDurationOutput }}
      </div>
    </template>
  </CTooltip>
</template>