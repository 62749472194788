import QueueItem from "~/dto/queue/QueueItem";
import {DateTime, Duration} from "luxon";

export const waitingDuration: (item: QueueItem) => Duration | null = function (item: QueueItem) {
    return item.isArrived
      ? DateTime.now().diff(item.registeredAt, ['hours', 'minutes'])
      : (
        item.isCalled && item.calledAt
          ? item.calledAt.diff(item.registeredAt, ['hours', 'minutes'])
          : null
      );
};
